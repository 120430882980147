<template>
    <mdb-modal :show="visible" @close="closeModal">
        <mdb-modal-header>
            <mdb-modal-title>Register for Early Bird Package</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body>
            <validation-observer slim ref="registerForm">
                <mdb-container fluid>
                    <mdb-row>
                        <mdb-col col="12">
                            <validation-provider :name="$t('name')" rules="required" v-slot="{ errors, touched }" slim>
                                <mdb-input :label="$t('name')" size="sm" v-model="form.name"
                                    :invalidFeedback="errors[0]" :isValid="!!!errors[0]" :validation="touched" />
                            </validation-provider>
                        </mdb-col>
                    </mdb-row>
                    <mdb-row>
                        <mdb-col col="12">
                            <validation-provider :name="$t('phone_number')" rules="required"
                                v-slot="{ errors, touched }" slim>
                                <mdb-input :label="$t('phone_number')" size="sm" v-model="form.phone_number"
                                    :invalidFeedback="errors[0]" :isValid="!!!errors[0]" :validation="touched" />
                            </validation-provider>
                        </mdb-col>
                    </mdb-row>
                    <mdb-row>
                        <mdb-col col="12">
                            <validation-provider :name="$t('email')" rules="required" v-slot="{ errors, touched }" slim>
                                <mdb-input :label="$t('email')" size="sm" v-model="form.email"
                                    :invalidFeedback="errors[0]" :isValid="!!!errors[0]" :validation="touched" />
                            </validation-provider>
                        </mdb-col>
                    </mdb-row>
                    <mdb-row class="mb-3">
                        <mdb-col col="12">
                            <mdb-btn block @click="registerUser" color="success" size="sm">{{
                            $t('register')
                            }}</mdb-btn>
                        </mdb-col>
                    </mdb-row>
                </mdb-container>
            </validation-observer>
        </mdb-modal-body>
        <Loading v-if="loading" />
    </mdb-modal>
</template>

<script>
import { mdbModal, mdbModalBody, mdbContainer, mdbRow, mdbCol, mdbInput, mdbBtn, mdbModalHeader, mdbModalTitle } from 'mdbvue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Loading from './util/Loading.vue'
export default {
    props: {
        visible: {
            type: Boolean,
            required: true,
        },
        close: {
            type: Function,
            required: true,
        },
    },
    components: {
        mdbModal,
        mdbModalBody,
        Loading,
        ValidationProvider,
        ValidationObserver,
        mdbContainer, mdbRow, mdbCol, mdbInput,
        mdbBtn, mdbModalHeader, mdbModalTitle
    },
    data: () => ({
        loading: false,
        index: 0,
        form: {
            name: "",
            email: "",
            phone_number: "",
            option: ""
        }
    }),
    methods: {
        closeModal() {
            this.close()
        },
        setLoading(loading) {
            this.loading = loading
        },
        registerUser() {
            console.log('register')
        }
    },
}
</script>
