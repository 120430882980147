<template>
    <mdb-container fluid class="py-5 bg-customize">
        <h3 class="h3-responsive text-white font-weight-bold text-center my-5">
            {{$t('feature_title_1')}}
            <span class="small">{{$t('of')}}</span>
            {{$t('versality')}}
        </h3>
        <mdb-container>
            <mdb-row class="mb-5">
                <mdb-col sm="3" v-animateOnScroll="'flipInX'">
                    <mdb-card testimonial>
                        <mdb-card-up class="lighten-1 ripe-malinka-gradient"></mdb-card-up>
                        <mdb-card-avatar color="white" class="mx-auto"><img :src="require('@/assets/f1.png')"
                                class="rounded-circle">
                        </mdb-card-avatar>
                        <mdb-card-body>
                            <p> FreeHold</p>
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
                <mdb-col sm="3" v-animateOnScroll="'flipInX'">
                    <mdb-card testimonial>
                        <mdb-card-up class="lighten-1 ripe-malinka-gradient"></mdb-card-up>
                        <mdb-card-avatar color="white" class="mx-auto"><img :src="require('@/assets/f2.png')"
                                class="rounded-circle">
                        </mdb-card-avatar>
                        <mdb-card-body>
                            <p> 674 Low Dense Units</p>
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
                <mdb-col sm="3" v-animateOnScroll="'flipInX'">
                    <mdb-card testimonial>
                        <mdb-card-up class="lighten-1 ripe-malinka-gradient"></mdb-card-up>
                        <mdb-card-avatar color="white" class="mx-auto">
                            <img :src="require('@/assets/f3.png')" class="rounded-circle">
                        </mdb-card-avatar>
                        <mdb-card-body>
                            <p> Fully | Partial Furnished</p>
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
                <mdb-col sm="3" v-animateOnScroll="'flipInX'">
                    <mdb-card testimonial>
                        <mdb-card-up class="lighten-1 ripe-malinka-gradient"></mdb-card-up>
                        <mdb-card-avatar color="white" class="mx-auto"><img :src="require('@/assets/f4.png')"
                                class="rounded-circle">
                        </mdb-card-avatar>
                        <mdb-card-body>
                            <p> Easy Access to Facilities</p>
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
            </mdb-row>
            <mdb-row class="mb-5">
                <mdb-col sm="3" v-animateOnScroll="'flipInX'">
                    <mdb-card testimonial>
                        <mdb-card-up class="lighten-1 ripe-malinka-gradient"></mdb-card-up>
                        <mdb-card-avatar color="white" class="mx-auto"><img :src="require('@/assets/f1.png')"
                                class="rounded-circle">
                        </mdb-card-avatar>
                        <mdb-card-body>
                            <p> FreeHold</p>
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
                <mdb-col sm="3" v-animateOnScroll="'flipInX'">
                    <mdb-card testimonial>
                        <mdb-card-up class="lighten-1 ripe-malinka-gradient"></mdb-card-up>
                        <mdb-card-avatar color="white" class="mx-auto"><img :src="require('@/assets/f2.png')"
                                class="rounded-circle">
                        </mdb-card-avatar>
                        <mdb-card-body>
                            <p> 674 Low Dense Units</p>
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
                <mdb-col sm="3" v-animateOnScroll="'flipInX'">
                    <mdb-card testimonial>
                        <mdb-card-up class="lighten-1 ripe-malinka-gradient"></mdb-card-up>
                        <mdb-card-avatar color="white" class="mx-auto">
                            <img :src="require('@/assets/f3.png')" class="rounded-circle">
                        </mdb-card-avatar>
                        <mdb-card-body>
                            <p> Fully | Partial Furnished</p>
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
                <mdb-col sm="3" v-animateOnScroll="'flipInX'">
                    <mdb-card testimonial>
                        <mdb-card-up class="lighten-1 ripe-malinka-gradient"></mdb-card-up>
                        <mdb-card-avatar color="white" class="mx-auto"><img :src="require('@/assets/f4.png')"
                                class="rounded-circle">
                        </mdb-card-avatar>
                        <mdb-card-body>
                            <p> Easy Access to Facilities</p>
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
            </mdb-row>
        </mdb-container>
    </mdb-container>


</template>

<script>
import { mdbRow, mdbCol, mdbContainer, mdbCard, mdbCardBody, mdbCardUp, mdbCardAvatar, animateOnScroll } from 'mdbvue'
export default {
    name: 'Features',
    components: {
        mdbRow, mdbCol, mdbContainer, mdbCard, mdbCardBody, mdbCardUp, mdbCardAvatar
    },
    directives: {
        animateOnScroll
    }
}
</script>

<style scoped>
.bg-customize {
    background-image: url('~@/assets/2.jpg');
    background-repeat: no-repeat;
}
</style>