<template>
    <mdb-container>
        <h2 class="h2-responsive text-elegant font-weight-bold text-center mb-5">
            Developer Track Records
        </h2>
        <mdb-row>
            <mdb-col sm="6">
                <video class="video-fluid z-depth-1" autoplay loop controls muted>
                    <source :src="require('@/assets/video_2.mp4')" type="video/mp4" />
                </video>
            </mdb-col>
            <mdb-col sm="6">
                <video class="video-fluid z-depth-1" autoplay loop controls muted>
                    <source :src="require('@/assets/video_1.mp4')" type="video/mp4" />
                </video>
            </mdb-col>
        </mdb-row>
    </mdb-container>
</template>

<script>

import {
    mdbRow, mdbCol, mdbContainer
} from 'mdbvue'
export default {
    name: 'home',
    components: {
        mdbRow, mdbCol,
        mdbContainer
    },
}
</script>