import { extend } from 'vee-validate'
import {
  email,
  max,
  min,
  numeric,
  min_value,
  max_value,
  between,
} from 'vee-validate/dist/rules'

extend('required', {
  validate(value) {
    return {
      required: true,
      valid: ['', null, undefined].indexOf(value) === -1,
    }
  },
  message: (fieldName) => {
    return `The ${fieldName} field is required`
  },
  computesRequired: true,
})

extend('email', {
  ...email,
  message: 'The {_field_} field must be an email',
})

extend('max_length', {
  ...max,
  params: ['length'],
  message: 'The {_field_} field cannot exceed {length} characters',
})

extend('min_length', {
  ...min,
  params: ['length'],
  message: 'The {_field_} field must be at least {length} characters',
})

extend('numeric', {
  ...numeric,
  params: ['min'],
  message: 'The {_field_} field must be numeric',
})

extend('min_value', {
  ...min_value,
  params: ['min'],
  message: 'The {_field_} field must have at least {min}',
})

extend('max_value', {
  ...max_value,
  params: ['max'],
  message: 'The {_field_} field must be lower than {max}',
})

extend('greaterThan', {
  params: ['target', 'isCheckEqual'],
  validate(value, { target, isCheckEqual = false }) {
    value = Number(value)
    target = Number(target)

    if (isCheckEqual && value === target) return true

    return Number(value) > Number(target)
  },
  message: 'The {_field_} must be greater than {target}',
})

extend('lowerThan', {
  params: ['target'],
  validate(value, { target }) {
    return Number(value) < Number(target)
  },
  message: 'The {_field_} field must be lower then {target}',
})

extend('no_space', {
  validate(value) {
    return !/\s/g.test(value)
  },
  message: 'No space are allowed.',
})

extend('no_emoji', {
  validate(value) {
    let emojiRegex = /[\ufff9-\uffff\p{Extended_Pictographic}]/u

    // Check if the message contains emoji and special character
    return !emojiRegex.test(value)
  },
  message: 'Emojis & Special Characters are not allowed.',
})

extend('between', {
  ...between,
  message: 'The {_field_} must be between {min} and {max}',
})

extend('trim_space', {
  validate(value) {
    return !/^\s+|\s+$/g.test(value)
  },
  message: `No leading or trailing space is allowed.`,
})

extend('synonym', {
  validate(value) {
    return !/(\S)#|#(\S)|#/g.test(value)
  },
  message: `No '#' allowed in synonym`,
})

extend('alpha_numeric', {
  validate(value) {
    // return /^[A-Za-z0-9 ]+$/.test(value) --> English & Numbers (Explicit)

    // Escape most of the punctuations (Non common characters)
    return !/[\p{P}|\p{S}]/gu.test(value)
  },
  message: `The {_field_} field may only contain alphabet or numeric characters`,
})

extend('decimal', {
  params: ['precision'],
  validate(value, { precision }) {
    // Regex string to dynamically accept dynamic param precision places
    const regex = '^(\\d+(\\.\\d{0,' + precision + '})?)$'
    // Use Javascript regex contructor to convert regex string to regex with flags
    const newRegex = new RegExp(regex)
    // Test value with new regex
    return newRegex.test(value)
  },
  message: `Only allow numeric or contains exactly {precision} decimal places`,
})

extend('username_regex', {
  validate(value) {
    let usernameRegex = /[^A-Za-z0-9_]/u

    // Check if the message contains emoji and special character
    return !usernameRegex.test(value)
  },
  message: 'Only accept letters, numbers and underscore.',
})

extend('snake_case', {
  validate(value) {
    let specialChars = /[\W]/u

    // Check if the message contains emoji and special character
    return !specialChars.test(value)
  },
  message: 'Only accept letters, numbers and underscore.',
})

// extend('required', {
//   ...required,
//   message: (fieldName, placeholders) => {
//     const field = Vue.filter('capitalize')(fieldName)
//     return `The ${field} field is required`
//   },
// })

// extend('numeric', {
//   ...numeric,
//   message: (fieldName, placeholders) => {
//     const field = Vue.filter('capitalize')(fieldName)
//     return `The ${field} field may only contain numeric characters`
//   },
// })

// extend('mobile_no', {
//   validate: (value) => {
//     const pattern = new RegExp('^(01)[0-46-9][0-9]{7,8}$')
//     return !!pattern.test(value)
//   },
//   message: 'Eg. 012-3388 xxx, 011-3388 xxxx',
// })
