<template>
  <mdb-container fluid>
    <Navbar @register="showRegisterModal" />
    <ProjectMain />
    <Location />
    <Features />
    <UnitTypes />
    <Collaboration />
    <Gallery />
    <VideoSection />
    <mdb-row class="my-5 py-5">
      <mdb-col col="12" class="text-center">
        <h1 class="h1-responsive">Niama faster place your booking now , susah payah i do this copy here and there
          convert here and there</h1>
      </mdb-col>
    </mdb-row>
    <RegisterModal :visible="register_modal" :close="closeModal" />
  </mdb-container>
</template>

<script>

import {
  mdbContainer, mdbRow, mdbCol
} from 'mdbvue'
import Navbar from '../components/Navbar'
import ProjectMain from '../components/ProjectMain'
import Features from '../components/Features.vue'
import Location from '../components/Location.vue'
import UnitTypes from '../components/UnitTypes.vue'
import Collaboration from '../components/Collaboration.vue'
import RegisterModal from '../components/RegisterModal.vue'
import Gallery from '../components/Gallery.vue'
import VideoSection from '../components/video.vue'
export default {
  name: 'home',
  components: {
    mdbContainer, Navbar, ProjectMain, Features, Location, UnitTypes,
    Collaboration, RegisterModal, Gallery, VideoSection, mdbRow, mdbCol
  },
  data: () => ({
    register_modal: false,
  }),
  methods: {
    closeModal() {
      this.register_modal = false
    },
    showRegisterModal() {
      this.register_modal = true
    }
  }
}
</script>