<template>
  <mdb-row>
    <mdb-col col="12">
      <mdb-view :src="require('@/assets/main.png')" alt="Scarlets Main Photo">
        <a>
          <mdb-mask overlay="white-slight"></mdb-mask>
        </a>
      </mdb-view>
    </mdb-col>
  </mdb-row>

</template>

<script>
import { mdbView, mdbMask, mdbRow, mdbCol } from 'mdbvue'
export default {
  name: 'ProjectMain',
  components: {
    mdbView, mdbMask, mdbRow, mdbCol
  }
}
</script>