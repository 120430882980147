<template>
    <mdb-navbar position="top" dark color="pink darken-4" scrolling>
        <mdb-container>
            <mdb-navbar-brand href="/" @click.native="navigateHome">
                <img :src="require('@/assets/logo_horizontal.png')" style="height: 40px" />
            </mdb-navbar-brand>
            <mdb-navbar-nav>
                <mdb-nav-item href="#" active>Home</mdb-nav-item>
            </mdb-navbar-nav>
            <mdb-navbar-toggler>
                <mdb-navbar-nav right>
                    <mdb-btn color="primary" @click="emitRegister" size="sm">{{
                    $t('register')
                    }}</mdb-btn>
                </mdb-navbar-nav>
            </mdb-navbar-toggler>
        </mdb-container>
    </mdb-navbar>
</template>

<script>
import {
    mdbContainer,
    mdbNavbar,
    mdbNavbarToggler,
    mdbNavbarNav,
    mdbNavbarBrand,
    mdbBtn,
    mdbNavItem
} from 'mdbvue'
export default {
    name: 'landing',
    components: {
        mdbContainer,
        mdbNavbar,
        mdbNavbarToggler,
        mdbNavbarNav,
        mdbNavbarBrand,
        mdbBtn,
        mdbNavItem
    },
    methods: {
        emitRegister() {
            this.$emit('register')
        },
        navigateHome() {
            window.location.href = '/'
        },
    },
}
</script>