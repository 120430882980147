<template>
    <mdb-container fluid class="py-5">
        <h2 class="h2-responsive text-elegant font-weight-bold text-center mb-5">
            Gallery
        </h2>
        <mdb-container>
            <mdb-row>
                <mdb-col>
                    <mdb-lightbox gap gallery @hide="closeLightbox" :imgs="images" :index="lightbox_index"
                        :visible="is_lightbox">
                    </mdb-lightbox>
                </mdb-col>
            </mdb-row>

        </mdb-container>
    </mdb-container>


</template>

<script>
import {
    mdbRow, mdbCol, mdbContainer,
    animateOnScroll,
    mdbLightbox
} from 'mdbvue'
export default {
    name: 'Features',
    components: {
        mdbRow, mdbCol, mdbContainer,
        mdbLightbox
    },
    directives: {
        animateOnScroll
    },
    data: () => ({
        images: [
            {
                img: require('@/assets/g1.jpg'),
                caption: "Type A"
            },
            {
                img: require('@/assets/g2.jpg'),
                caption: "Type B"
            },
            {
                img: require('@/assets/g3.jpg'),
                caption: "Type C"
            },
            {
                img: require('@/assets/g4.jpg'),
                caption: "Type C"
            },
            {
                img: require('@/assets/g5.jpg'),
                caption: "Type C"
            },
            {
                img: require('@/assets/g6.jpg'),
                caption: "Type C"
            },
            {
                img: require('@/assets/g7.jpg'),
                caption: "Type C"
            },
            {
                img: require('@/assets/g8.jpg'),
                caption: "Type C"
            },
            {
                img: require('@/assets/g9.jpg'),
                caption: "Type C"
            }
        ],
        is_lightbox: false,
        lightbox_index: 0,
    }),
    methods: {
        showLightbox(index) {
            this.lightbox_index = index
            this.is_lightbox = true
        },
        closeLightbox() {
            this.is_lightbox = false
        }
    }
}
</script>