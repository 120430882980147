<template>
    <mdb-container fluid class="py-5">
        <h2 class="h2-responsive text-elegant font-weight-bold text-center mb-5">
            Layouts
        </h2>
        <mdb-container>
            <mdb-row class="mb-5">
                <mdb-col sm="4" v-animateOnScroll="'fadeIn'">
                    <mdb-card wide ecommerce>
                        <mdb-view style="cursor:pointer" @click.native="showLightbox(0)" overlay="white-slight" cascade>
                            <mdb-card-image :src="require('@/assets/t1.jpg')" />
                        </mdb-view>
                        <mdb-card-body class="text-center" cascade>
                            <a href="" class="text-muted">
                                <h5>Type A</h5>
                            </a>
                            <mdb-card-title>
                                <strong><a href="">452 SF 42 SM 1R1B</a></strong>
                            </mdb-card-title>
                            <mdb-card-text>Lorem ipsum dolor sit amet, consectetur adipisicing minima veniam elit.
                            </mdb-card-text>
                            <mdb-card-footer>
                                <mdb-btn size="sm" color="primary">Book NOW</mdb-btn>
                            </mdb-card-footer>
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
                <mdb-col sm="4" v-animateOnScroll="'fadeIn'">
                    <mdb-card wide ecommerce>
                        <mdb-view style="cursor:pointer" @click.native="showLightbox(1)" overlay="white-slight" cascade>
                            <mdb-card-image :src="require('@/assets/t2.jpg')" />
                        </mdb-view>
                        <mdb-card-body class="text-center" cascade>
                            <a href="" class="text-muted">
                                <h5>Type B</h5>
                            </a>
                            <mdb-card-title>
                                <strong><a href="">452 SF 42 SM 1R1B</a></strong>
                            </mdb-card-title>
                            <mdb-card-text>Lorem ipsum dolor sit amet, consectetur adipisicing minima veniam elit.
                            </mdb-card-text>
                            <mdb-card-footer>
                                <mdb-btn size="sm" color="primary">Book NOW</mdb-btn>
                            </mdb-card-footer>
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
                <mdb-col sm="4" v-animateOnScroll="'fadeIn'">
                    <mdb-card wide ecommerce>
                        <mdb-view style="cursor:pointer" @click.native="showLightbox(2)" overlay="white-slight" cascade>
                            <mdb-card-image :src="require('@/assets/t3.jpg')" />
                        </mdb-view>
                        <mdb-card-body class="text-center" cascade>
                            <a href="" class="text-muted">
                                <h5>Type C</h5>
                            </a>
                            <mdb-card-title>
                                <strong><a href="">452 SF 42 SM 1R1B</a></strong>
                            </mdb-card-title>
                            <mdb-card-text>Lorem ipsum dolor sit amet, consectetur adipisicing minima veniam elit.
                            </mdb-card-text>
                            <mdb-card-footer>
                                <mdb-btn size="sm" color="primary">Book NOW</mdb-btn>
                            </mdb-card-footer>
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
            </mdb-row>
            <mdb-lightbox @hide="closeLightbox" :imgs="images" :index="lightbox_index" :visible="is_lightbox">
            </mdb-lightbox>
        </mdb-container>
    </mdb-container>


</template>

<script>
import {
    mdbRow, mdbCol, mdbContainer, mdbCard,
    mdbCardImage,
    mdbCardBody,
    mdbCardTitle,
    mdbCardFooter,
    mdbCardText,
    mdbView,
    animateOnScroll,
    mdbBtn,
    mdbLightbox
} from 'mdbvue'
export default {
    name: 'Features',
    components: {
        mdbRow, mdbCol, mdbContainer, mdbCard,
        mdbCardImage,
        mdbCardBody,
        mdbCardTitle,
        mdbCardFooter,
        mdbCardText,
        mdbView,
        mdbBtn,
        mdbLightbox
    },
    directives: {
        animateOnScroll
    },
    data: () => ({
        images: [{
            img: require('@/assets/t1.jpg'),
            caption: "Type A"
        },
        {
            img: require('@/assets/t2.jpg'),
            caption: "Type B"
        },
        {
            img: require('@/assets/t3.jpg'),
            caption: "Type C"
        }
        ],
        is_lightbox: false,
        lightbox_index: 0,
    }),
    methods: {
        showLightbox(index) {
            this.lightbox_index = index
            this.is_lightbox = true
        },
        closeLightbox() {
            this.is_lightbox = false
        }
    }
}
</script>