<template>
    <mdb-container fluid class="bg-customize">
        <div class="text-center mb-5">
            <img :src="require('@/assets/bg_pattern_1.png')" style="height:150px" />
        </div>
        <h2 class="h2-responsive text-elegant font-weight-bold text-center mb-5">
            Our amazing Helpers
        </h2>
        <mdb-container>
            <mdb-row class="mb-5">
                <mdb-col sm="6">
                    <mdb-row>
                        <mdb-col col="4">
                            <img :src="require('@/assets/collap_1.jpg')" class="img-fluid rounded" />
                        </mdb-col>
                        <mdb-col col="8">
                            <h4 class="font-weight-bold mb-2">Sozai Mana Mana</h4>
                            <h6 class="font-weight-bold grey-text mb-1">Professional Shyt Cleaner</h6>
                            <div class="grey-text">55+ Years in shyt cleaning.</div>
                            <div class="grey-text">55+ Years in shyt cleaning.</div>
                            <div class="grey-text">55+ Years in shyt cleaning.</div>
                        </mdb-col>
                    </mdb-row>
                </mdb-col>
                <mdb-col sm="6" class="mb-5">
                    <mdb-row>
                        <mdb-col col="4">
                            <img :src="require('@/assets/collap_2.jpg')" class="img-fluid rounded" />
                        </mdb-col>
                        <mdb-col col="8">
                            <h4 class="font-weight-bold mb-2">House Key Weh</h4>
                            <h6 class="font-weight-bold grey-text mb-1">Professional Loan Kedai</h6>
                            <div class="grey-text">Very High Interest rate</div>
                            <div class="grey-text">Cut 韭菜.</div>
                            <div class="grey-text">Professional 大耳窿 group.</div>
                        </mdb-col>
                    </mdb-row>
                </mdb-col>
            </mdb-row>
            <div class="text-left mb-5">
                <img :src="require('@/assets/bg_pattern_2.png')" style="height:150px" />
            </div>
        </mdb-container>
    </mdb-container>


</template>

<script>
import {
    mdbRow, mdbCol, mdbContainer,
    animateOnScroll,
} from 'mdbvue'
export default {
    name: 'Features',
    components: {
        mdbRow, mdbCol, mdbContainer,
    },
    directives: {
        animateOnScroll
    },
}
</script>

<style scoped>
.bg-customize {
    background-color: #f1e7db;
}
</style>