<template>
    <mdb-container class="my-5">
        <h2 class="h2-responsive text-elegant font-weight-bold text-center mb-2">
            Location
        </h2>
        <mdb-row class="mb-5" v-animateOnScroll="'fadeIn'">
            <mdb-col col="12">
                <mdb-card reverse>
                    <mdb-view hover cascade>
                        <a href="#!">
                            <mdb-card-image :src="require('@/assets/1.jpg')" alt="Card image cap">
                            </mdb-card-image>
                            <mdb-mask waves overlay="white-slight"></mdb-mask>
                        </a>
                    </mdb-view>
                    <mdb-card-body class="text-center" cascade>
                        <mdb-card-title><strong>AN ICONIC BUSINESS ADDRESS IN KUALA LUMPUR CITY CENTRE</strong>
                        </mdb-card-title>
                        <mdb-card-text>
                            Scarlet is a colour of many expressions. One that celebrates various shades of passion,
                            opulence and character. This
                            irresistible energy is expressed through iconic architecture and exquisite refinement.

                            Here’s presenting a new realm of versatility ignited by passion.

                            Welcome to Scarletz Suites.
                        </mdb-card-text>
                    </mdb-card-body>
                </mdb-card>
            </mdb-col>
        </mdb-row>
        <mdb-row class="mb-5" v-animateOnScroll="'fadeIn'">
            <mdb-col col="12">
                <mdb-card reverse>
                    <mdb-view hover cascade>
                        <a href="#!">
                            <mdb-card-image :src="require('@/assets/3.jpg')" alt="Card image cap">
                            </mdb-card-image>
                            <mdb-mask waves overlay="white-slight"></mdb-mask>
                        </a>
                    </mdb-view>
                    <mdb-card-body class="text-center" cascade>
                        <mdb-card-title><strong>KL GOLDEN TRIANGLE</strong>
                        </mdb-card-title>
                        <mdb-card-text>
                            Jalan Yap Kuan Seng, Next to Jalan Tun Razak
                            Only 10 Minutes Walking Distance to Suria KLCC
                            Walking Distance to LRT & MRT Station
                        </mdb-card-text>
                    </mdb-card-body>
                </mdb-card>
            </mdb-col>
        </mdb-row>
    </mdb-container>


</template>

<script>
import { mdbRow, mdbCol, mdbContainer, animateOnScroll, mdbCard, mdbCardImage, mdbCardBody, mdbCardTitle, mdbCardText, mdbView, mdbMask } from 'mdbvue'
export default {
    name: 'Features',
    components: {
        mdbRow, mdbCol, mdbContainer, mdbCard, mdbCardImage, mdbCardBody, mdbCardTitle, mdbCardText, mdbView, mdbMask
    },
    directives: {
        animateOnScroll
    }
}
</script>

<style scoped>
.bg-customize {
    background-image: url('~@/assets/2.jpg');
}
</style>